<template>
  <div class="ship-detail detail-wrapper" v-if="detailShow">
    <div v-show="loading" class="ship-loading"></div>
    <section class="add-ships shipDetail-wrapper">
      <header class="headerTitle">
        <div class="leftSide">
          <h2 @click="backToList">
            <i class="iconfont ch-icon-arrow_right backIcon"></i>
            {{specData.overview.name}}
          </h2>
        </div>
        <div class="rightSide btn-groups">
          <Tooltip content="In new window" class="actionable-icon">
            <div class="btn" @click="openNewWindow()">
              <i class="iconfont-in-new-window"></i>
            </div>
          </Tooltip>
        </div>
      </header>
      <ShipDetailItem class="detail-content" :specData="specData" @shipDeleted="backToList" :source="source"/>
    </section>
  </div>
</template>

<script>
import api from 'api'
import util from 'util'
import ShipDetailItem from './shipDetailItem.vue'

export default {
  props: {
    selectedRow: {
      type: Object
    }
  },
  data() {
    return {
      detailShow: false,
      loading: false,
      specData: {
        overview: {
          public: true
        }
      },
      currentId: null
    }
  },
  methods: {
    show() {
      Object.assign(this.$data, this.$options.data());
      this.detailShow = this.loading = true;
      this.currentId = this.selectedRow.id;
      this.getData();
    },
    hide() {
      this.detailShow = false;
    },
    backToList() {
      const query = Object.assign({}, this.$route.query);
      query.detail_Id && delete query.detail_Id;
      this.$router.replace({ path: "/ships/shipping", query: query });
      this.detailShow = false;
    },
    getData() {
      const params = {
        id: this.currentId || this.$route.query.detail_Id,
        via: this.$route.query.via,
        source: this.source
      };

      api
        .ship_detail_change(params)
        .then(res => {
          this.loading = false;
          res.id = parseInt(params.id);
          this.updateShipDetailUI(res)
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    updateShipDetailUI(res) {
      this.specData = {...res, id: this.currentId || this.$route.query.detail_Id};
    },
    openNewWindow() {
      let id = this.$route.query.detail_Id || this.currentId;
      const sourceQueryStr = this.specData.source ? `source=${this.specData.source}` : '';
      let subUrl = this.$route.query.via ? `shipDetail/${id}?${sourceQueryStr}&via=notification` : `shipDetail/${id}?${sourceQueryStr}`;
      util.openWindow(subUrl);
    }
  },
  computed: {
    source() {
      if(!this.selectedRow) return;
      return this.selectedRow?.source;
    }
  },
  components: {
    ShipDetailItem
  }
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.ship-detail {
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  min-width: 800px;
  overflow-y: hidden;
  background: var(--background-color);

  .btn-groups {
    display: inline-flex;
    .actionable-icon + .actionable-icon {
      margin-left: 8px;
    }
  }

  i, [class^="iconfont-"] {
    font-size: 16px;
    vertical-align: middle;
  }

  .ivu-tooltip {
    border-top: 0;
  }

  .shipDetail-wrapper {
    .detail-content {
      height: calc(100vh - 100px);
    }
  }
}
</style>
