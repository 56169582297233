<template>
  <div ref="mapbox-wrapper" class="map-wrapper">
    <mapbox ref="mapEl"
      access-token="pk.eyJ1IjoibWFwYm94LW9mZmljaWFsIiwiYSI6ImNsbzZxZ29lZTBsNTYycm56b3c4NTNkODUifQ.FiTV8QqMK4d2vag94tBo6g"
      :map-options="mapboxOptions" :nav-control="{ show: true, position: 'bottom-right' }"
      :geolocate-control="{ show: true, position: 'top-right' }" :scale-control="{ show: true, position: 'bottom-left' }"
      :fullscreen-control="{ show: true, position: 'top-right' }" @map-load="mapLoaded" @map-init="mapInit">
    </mapbox>
  </div>
</template>

<script type="text/ecmascript-6">
import mapbox from "mapbox-gl-vue";
import { mapGetters } from "vuex";
import util, { filter } from "util";
import appConstant from '@/common/constants/app.constant';
import '../../../../../public/static/style/mapbox-gl.css';

let dataShips;
let animation;

class MapboxGLButtonControl {
  constructor({
    className = "",
    title = "",
    eventHandler = () => { }
  }) {
    this._className = className;
    this._title = title;
    this._eventHandler = eventHandler;
  }

  onAdd() {
    this._btn = document.createElement("button");
    this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className;
    this._btn.type = "button";
    this._btn.title = this._title;
    this._btn.onclick = this._eventHandler;

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export default {
  props: {
    aisData: {
      type: Array,
      default: () => []
    },
    positionHistories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      index: 0,
      currCoordinates: [],
      then: new Date().getTime(),
      oldPositionSegments: [],
      drawing: false,
      mapInstance: null,
      resizeObserver: null
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.mapInstance && this.mapInstance.resize()
    });

    this.resizeObserver.observe(this.$refs['mapbox-wrapper']);
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs['mapbox-wrapper'])
  },
  computed: {
    ...mapGetters(["userInfo", "setting_mails"]),
    mapboxOptions() {
      const light = "mapbox://styles/mapbox-official/clmoxc5z401zg01quhmvh97xj"
      const dark = "mapbox://styles/mapbox-official/clmowmvem022a01r76lwl43e1"
      // const satellite = "mapbox://styles/mapbox-official/clmoxdj1401zh01r4exeud7zv"

      const config = {
        style: this.setting_mails.theme_colour !== appConstant.themeNames.TWILIGHT ? light : dark,
        zoom: 1,
        maxZoom: 20,
        minZoom: 1,
        scrollZoom: true,
      }

      this.aisData && this.aisData.length && (config.center = this.aisData[0].geometry.coordinates)

      return config
    }
  },
  watch: {
    positionHistories: {
      handler: function (val) {
        if (!val) return;
        this.drawHistoricalRoutes();
      },
      deep: true
    }
  },
  methods: {
    buildPositionSegments(positions) {
      let routes = [];
      let breakpoint = 0;

      positions.forEach((position, index, arr) => {
        if (index <= 0)
          return;
        const previousPosition = arr[index - 1];
        const onBackEarth = (previousPosition.lon < 0 != position.lon < 0) // one on west, one on east
          && Math.abs(previousPosition.lon) > 90 // current longitude is more than 90
          && Math.abs(position.lon) > 90; // previous longitude is more than 90
        if (onBackEarth) {
          const route = arr.slice(breakpoint, index);
          routes.push({ type: 'front', positions: route });

          const backRoute = arr.slice(index - 1, index + 1);
          routes.push({ type: 'back', positions: backRoute });

          breakpoint = index;
        }
      });

      if (breakpoint < positions.length - 1)
        routes.push({ type: 'front', positions: positions.slice(breakpoint, positions.length) })

      let lastRoute = routes[routes.length - 1];
      lastRoute && lastRoute.positions && lastRoute.positions.push({
        lon: this.currCoordinates[0],
        lat: this.currCoordinates[1]
      })
      return routes;
    },

    drawEarliestPosition(id) {

      this.mapInstance.getLayer(id) && this.mapInstance.removeLayer(id);
      this.mapInstance.getSource(id) && this.mapInstance.removeSource(id);
      if (this.positionHistories.length > 1) {
        const { lon, lat } = this.positionHistories[0];
        this.drawPoint(id, [lon, lat], 'earliest-position');
      }
    },

    async drawHistoricalRoutes() {
      cancelAnimationFrame(animation);
      this.resetHistoricalRoutes();
      this.resetOldPositions();
      this.drawEarliestPosition('earliestPosition');
      this.oldPositionSegments = this.buildPositionSegments(this.positionHistories);
      for (let index = 0; index < this.oldPositionSegments.length; index++) {
        const { positions, type } = this.oldPositionSegments[index];
        this.mapInstance.addSource(`route${index}`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [{
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: []
              }
            }]
          }
        });
        const layer = {
          id: `route${index}`,
          source: `route${index}`,
          filter: ["!has", "point_count"],
          type: "line",
          paint: {
            "line-width": 2,
            "line-color": type == 'front' ? "#007cbf" : 'rgba(0, 124, 191, 0.5)'
          }
        }
        type == 'back' && (layer.paint["line-dasharray"] = [2, 2]);
        this.mapInstance.addLayer(layer);

        if (positions.length < 2)
          return;

        this.index = 0;
        await this.animateLine(`route${index}`, positions);
      }

      this.updateOldPositions();
    },

    animateLine(sourceName, positions) {
      let self = this;
      return new Promise((resolve, reject) => {
        drawLine();

        function drawLine() {
          if (self.index > positions.length - 1) {
            resolve();
            cancelAnimationFrame(animation);
            return;
          }

          const { lon, lat } = positions[self.index];
          const routeData = self.mapInstance.getSource(sourceName)._data;
          routeData.features[0].geometry.coordinates.push([lon, lat]);
          self.mapInstance.getSource(sourceName).setData(routeData);

          self.index += 1;
          animation = requestAnimationFrame(drawLine);
        }
      });
    },

    resetHistoricalRoutes() {
      this.oldPositionSegments.forEach((route, index) => {
        this.mapInstance.getLayer(`route${index}`) && this.mapInstance.removeLayer(`route${index}`);
        this.mapInstance.getSource(`route${index}`) && this.mapInstance.removeSource(`route${index}`);
      })
    },

    resetOldPositions() {
      dataShips.features = [];
      this.mapInstance.getSource('ships').setData(dataShips);
    },

    updateOldPositions() {
      for (const o of this.positionHistories) {
        const oldPosition = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [o.lon, o.lat]
          },
          properties: {
            icon: "static-tanker" // red color
          }
        }

        oldPosition.properties = Object.assign({}, oldPosition.properties, o);
        dataShips.features.push(oldPosition);
      }
      this.mapInstance.getSource('ships').setData(dataShips);
    },

    mapInit(map) {
      this.mapInstance = map;
    },

    pulsingDot(map) {
      const size = 150;
      return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        onAdd: function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },

        render: function () {
          // keep the map repainting
          map.triggerRepaint();

          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = size / 2 * 0.3;
          const outerRadius = size / 2 * 0.7 * t + radius;
          const context = this.context;

          // draw outer circle
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
          context.fillStyle = 'rgba(200, 255, 200,' + (1 - t) + ')';
          context.fill();

          // draw inner circle
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = 'rgba(100, 255, 100, 1)';
          context.strokeStyle = 'white';
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // update this image's data with data from the canvas
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // return `true` to let the map know that the image was updated
          return true;
        }
      }
    },
    mapLoaded(map) {
      const _this = this;
      const currFeatures = [...this.aisData];

      this.currCoordinates = this.aisData[0].geometry.coordinates;

      let mooredStatus = false
      if (currFeatures.length === 1) {
        mooredStatus = (currFeatures[0].properties.status === 'moored') || (currFeatures[0].properties.speed === 0);
      }

      dataShips = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" }
        },
        features: []
      };

      const currentPos = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" }
        },
        features: currFeatures
      }

      map.addSource("current", {
        type: "geojson",
        data: currentPos,
        cluster: true,
        clusterMaxZoom: 20,
        clusterRadius: 100
      });

      map.addSource("ships", {
        type: "geojson",
        data: dataShips,
        cluster: true,
        clusterMaxZoom: 16,
        clusterRadius: 20
      });

      const replayBtn = new MapboxGLButtonControl({
        className: "mapbox-gl-replay",
        title: "Replay animation",
        eventHandler: async () => {
          this.drawHistoricalRoutes();
        }
      });
      currFeatures.length === 1 && map.addControl(replayBtn, "top-right");

      map.loadImage("/static/img/icon-group.png", (error, image) => {
        map.addImage("icon-groups", image, { pixelRatio: 2 });
        map.addLayer({
          id: "clusters",
          type: "symbol",
          source: "ships",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-size": 10,
            "icon-image": "icon-groups",
            "icon-size": 1,
            "icon-padding": 4,
            "icon-offset": [-3, -3],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"]
          },
          paint: {
            "text-color": "#ffffff"
          }
        });

        const pulsing = this.pulsingDot(map);

        map.addImage(
          'pulsing-dot',
          pulsing,
          { pixelRatio: 2.5 }
        );

        map.addLayer({
          id: "currentCluster",
          type: "symbol",
          source: "current",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-size": 14,
            "icon-image": "pulsing-dot",
            "icon-size": 1,
            "text-font": ["Arial Unicode MS Bold"]
          },
          paint: {
            "text-color": "#ffffff"
          }
        });

        map.addLayer({
          id: "current",
          type: "symbol",
          source: "current",
          filter: ["!has", "point_count"],
          layout: {
            "icon-image": mooredStatus ? "pulsing-dot" : ["get", "icon"],
            "icon-size": 1.5,
            "icon-allow-overlap": true,
            "icon-rotate": ["get", "heading"]
          },
        });
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });
      const loadImage = (url, name) => {
        return new Promise((resolve, reject) => {
          map.loadImage(url, (error, image) => {
            map.addImage(name, image, { pixelRatio: 2 });
            if (error) { return reject(error) }
            resolve(true);
          });
        })
      }
      const promise1 = loadImage("/static/img/vessel.png", "dry");
      const promise2 = loadImage("/static/img/vessel.png", "bulk");
      const promise3 = loadImage("/static/img/vessel.png", "tanker");
      const promise4 = loadImage("/static/img/vessel.png", "gas");
      const promise5 = loadImage("/static/img/static-r.png", "static-tanker");
      const promise6 = loadImage("/static/img/earliest-position.png", "earliest-position");

      Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6
      ]).then(() => {
        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "ships",
          filter: ["!has", "point_count"],
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-rotate": ["get", "heading"]
          }
        });
      });

      this.showPopupHovering(popup, "unclustered-point");
      this.showPopupHovering(popup, "current");

      map.on("mouseenter", "clusters", function () {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", function () {
        map.getCanvas().style.cursor = "";
      });
      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "unclustered-point", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
      map.on("mouseleave", "current", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      if (currFeatures.length > 1) {
        map.on("click", "current", function (e) {
          _this.openNewWindow(e.features[0].properties.ship_id);
        });
      }
    },
    showPopupHovering(popup, point) {
      this.mapInstance.on("mouseenter", point, e => {
        this.mapInstance.getCanvas().style.cursor = "pointer";
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        let data = e.features[0].properties;
        let html = `
          <div style="font-size:12px;color:#194D78;line-height:18px;">
            <p style="margin-bottom:5px;">
              <strong style="color:#184d77;text-transform:Capitalize;">
                ${data.name || this.aisData[0].properties.name}
              </strong>
            </p>
            <p style="margin-bottom:2px;">
              IMO : ${data.lrimo || this.aisData[0].properties.lrimo}
            </p>
            <p style="margin-bottom:2px;">
              Type: ${data.type_level2 || this.aisData[0].properties.type_level2}
            </p>
            <p style="margin-bottom:2px;">
              DWT : ${data.deadweight || this.aisData[0].properties.deadweight}
            </p>
            <p style="margin-bottom:2px;">
              LOA : ${data.length_overall_loa || this.aisData[0].properties.length_overall_loa}
            </p>
            <p style="margin-bottom:2px;">
              Speed : ${data.speed} KN
            </p>
            <p>Year Of Build : ${data.year_of_build}</p>
            <p style="margin-bottom:2px;">
              Status : ${data.status || "NA"}
            </p>
            <p style="margin-bottom:2px;">
              Updated : ${this.formatTimeAgo(data.record_at) || "NA"}
            </p>
          </div>`;
        popup
          .setLngLat(coordinates)
          .setHTML(html)
          .addTo(this.mapInstance);
      });
    },
    openNewWindow(currentId) {
      let subUrl = `shipDetail/${currentId}`;
      if (this.$route.query.via) {
        subUrl = `shipDetail/${currentId}?via=notification`;
      }
      util.openWindow(subUrl);
    },
    returnDate(date) {
      const utc = filter.toUTC(date);
      return this.$options.filters.format_date_time(utc);
    },
    formatTimeAgo(date) {
      const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
      const { interval, epoch } = this.getDuration(timeAgoInSeconds);
      const suffix = interval === 1 ? '' : 's';

      let timeAgo = `${interval} ${epoch}${suffix} ago`;
      let detailTime = this.returnDate(date);

      return `${timeAgo} (${detailTime} UTC)`;
    },
    getDuration(timeAgoInSeconds) {
      const epochs = [
        ['year', 31536000],
        ['month', 2592000],
        ['day', 86400],
        ['hour', 3600],
        ['minute', 60],
        ['second', 1]
      ];

      for (let [name, seconds] of epochs) {
        const interval = Math.floor(timeAgoInSeconds / seconds);

        if (interval >= 1) {
          return {
            interval: interval,
            epoch: name
          };
        }
      }
    },
    drawPoint(id, coordinates, icon) {
      this.mapInstance.addSource(id, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            geometry: { type: 'Point', coordinates: coordinates }
          }]
        }
      });
      this.mapInstance.addLayer({
        id: id,
        type: 'symbol',
        source: id,
        layout: {
          'icon-image': icon,
          "icon-size": 1.3,
          "icon-allow-overlap": true,
          "icon-rotate": ["get", "heading"]
        },
      })
    }
  },
  components: {
    mapbox
  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.map-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  #replayBtn {
    position: absolute;
    padding: 0 10px;
    top: 10px;
    left: 60px;
    border: none;

    &:focus {
      border: none;
    }
  }

  .mapboxgl-canvas {
    left: 0;
  }
}
</style>
